
import { Vue, Options } from "vue-class-component";
import { formatPrice } from "@/app/infrastructures/misc/Utils";
import { LastBalanceController } from "@/app/ui/controllers/LastBalanceController";
import { LastBalanceData } from "@/domain/entities/LastBalance";
import PageListLastBalance from "@/app/ui/views/finance/last-balance/components/page-list-last-balance.vue";

@Options({
  components: {
    PageListLastBalance
  }
})
export default class ListLastBalancePos extends Vue {
  tab = "pos";
  mounted() {
    LastBalanceController.initLastBalanceList(this.tab);
  }
  // Table
  columns = [
    {
      name: "Kode POS",
      styleHead: "w-3/12 text-left whitespace-no-wrap",
      styleBody: "text-red-lp-200 text-center",
      render: (item: LastBalanceData) => {
        return `<span class='text-left text-red-lp-200 flex justify-center'>${item.codeClientPartner}</span>`;
      }
    },
    {
      name: "Nama POS",
      styleHead: "w-3/12 text-left whitespace-no-wrap",
      styleBody: "",
      render: (item: LastBalanceData) => {
        return `<span class="overflow-ellipsis text-black-lp-300">${item.nameClientPartner}</span>`;
      }
    },
    {
      name: "Saldo Terakhir",
      styleHead: "w-3/12 text-left whitespace-no-wrap",
      render: (item: LastBalanceData) => {
        return `${this.formatBalanceStyle(item)}`;
      }
    },
    {
      name: "Transaksi Terakhir",
      styleHead: "w-3/12 text-left whitespace-no-wrap",
      render: (item: any) => {
        return `<div class='flex text-black-lp-300'>${item.lastTransaction}</div>`;
      }
    }
  ];

  formatBalanceStyle(item: LastBalanceData) {
    return `<span class="overflow-ellipsis ${
      item.lastBalance < 0 ? "text-red-lp-750" : "text-green-lp-100"
    }"> ${formatPrice(item.lastBalance, "+ ", item.currency)}</span>`;
  }
}
