import { container } from "tsyringe";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { Pagination } from "@/domain/entities/Pagination";
import { LastBalanceEntities } from "@/domain/entities/LastBalance";
import { RequestListPartner } from "@/data/payload/api/PartnerApiRequest";
import { LastBalancePresenter } from "@/app/ui/presenters/LastBalancePresenter";
import { RequestListLastBalance } from "@/data/payload/api/LastBalanceApiRequest";
import { BalanceData } from "@/domain/entities/Balance";
import { formatDate } from "@/app/infrastructures/misc/Utils";
import { RequestListClientManagement } from "@/data/payload/api/ClientManagementApiRequest";

export interface LastBalanceState {
  lastBalanceList: LastBalanceEntities;
  isLoading: boolean;
  isError: boolean;
  errorCause: string;
  search: string;
  firstRequest: boolean;
}

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: "finance_last_balance"
})
class LastBalanceStore extends VuexModule implements LastBalanceState {
  isLoading = false;
  isError = false;
  errorCause = "";
  lastBalanceList = new LastBalanceEntities(new Pagination(1, 20, 0), []);
  search = "";
  firstRequest = true;

  @Action
  public getListLastBalance(params: RequestListLastBalance) {
    const presenter = container.resolve(LastBalancePresenter);
    return presenter
      .getListLastBalance(params)
      .then((res: BalanceData[]) => {
        return res;
      })
      .catch((error: any) => {
        this.setLastBalanceList(
          new LastBalanceEntities(new Pagination(1, 20, 0), [])
        );
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
        return [];
      })
      .finally(() => {
        this.setLoading(false);
      });
  }
  @Mutation
  setLastBalanceDataToList(params: {
    balanceDatas: BalanceData[];
    type: string;
  }) {
    for (const index in this.lastBalanceList.data) {
      const balanceData = params.balanceDatas.find(
        (item: BalanceData) =>
          item.walletActorId ===
          this.lastBalanceList.data[index].idClientPartner
      );
      if (balanceData) {
        this.lastBalanceList.data[index].lastTransaction = formatDate(
          balanceData.walletUpdatedAt
        );
        let lastBalance = 0;
        if (params.type === "balance_cod") {
          lastBalance = balanceData.walletBalanceCod;
        }
        if (params.type === "balance") {
          lastBalance = balanceData.walletBalance;
        }
        this.lastBalanceList.data[index].lastBalance = lastBalance;
      }
    }
  }

  @Action
  public getListPartner(params: RequestListPartner) {
    this.setLoading(true);
    const presenter = container.resolve(LastBalancePresenter);
    presenter
      .getListPartner(params)
      .then(async (res: LastBalanceEntities) => {
        this.setLastBalanceList(res);
        this.setError(false);
        this.setErrorCause("");
        if (res.data.length === 0) {
          this.setLoading(false);
          return;
        }
        const responseLastBalance = await this.getListLastBalance(
          new RequestListLastBalance({
            actorIds: this.lastBalanceList.mappedDataIdToString(),
            actorType: "pos",
            limit: this.lastBalanceList.data.length
          })
        );
        this.setLastBalanceDataToList({
          balanceDatas: responseLastBalance,
          type: "balance"
        });
      })
      .catch((error: any) => {
        this.setLastBalanceList(
          new LastBalanceEntities(new Pagination(1, 20, 0), [])
        );
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
        this.setLoading(false);
      });
  }
  @Action
  public getListClientManagement(params: RequestListClientManagement) {
    this.setLoading(true);
    const presenter = container.resolve(LastBalancePresenter);
    presenter
      .getListClientManagement(params)
      .then(async (res: LastBalanceEntities) => {
        this.setLastBalanceList(res);
        this.setError(false);
        this.setErrorCause("");
        if (res.data.length === 0) {
          this.setLoading(false);
          return;
        }
        const responseLastBalance = await this.getListLastBalance(
          new RequestListLastBalance({
            actorIds: this.lastBalanceList.mappedDataIdToString(),
            actorType: "client",
            limit: this.lastBalanceList.data.length
          })
        );
        this.setLastBalanceDataToList({
          balanceDatas: responseLastBalance,
          type: params.isCod ? "balance_cod" : "balance"
        });
      })
      .catch((error: any) => {
        this.setLastBalanceList(
          new LastBalanceEntities(new Pagination(1, 20, 0), [])
        );
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
        this.setLoading(false);
      });
  }
  @Mutation
  setLastBalanceList(val: LastBalanceEntities) {
    this.lastBalanceList = val;
  }

  @Action
  fetchLastBalanceList(type: string) {
    this.setFirstRequest(false);
    switch (type) {
      case "pos":
        this.getListPartner(
          new RequestListPartner({
            version: "v2",
            isTotalData: true,
            page: this.lastBalanceList.pagination.page,
            limit: this.lastBalanceList.pagination.limit,
            search: this.search,
            type: "pos"
          })
        );
        break;
      case "client":
        this.getListClientManagement(
          new RequestListClientManagement({
            page: this.lastBalanceList.pagination.page,
            limit: this.lastBalanceList.pagination.limit,
            search: this.search
          })
        );
        break;
      case "client-cod":
        this.getListClientManagement(
          new RequestListClientManagement({
            page: this.lastBalanceList.pagination.page,
            limit: this.lastBalanceList.pagination.limit,
            search: this.search,
            isCod: true
          })
        );
        break;
      default:
        break;
    }
  }
  @Action
  public initLastBalanceList(type: string) {
    this.setFirstRequest(true);
    this.setLastBalanceList(
      new LastBalanceEntities(new Pagination(1, 20, 0), [])
    );
    this.setSearch("");
    this.setError(false);
    this.setErrorCause("");
    switch (type) {
      case "pos":
        this.getListPartner(
          new RequestListPartner({
            version: "v2",
            isTotalData: true,
            type: "pos"
          })
        );
        break;
      case "client":
        this.getListClientManagement(new RequestListClientManagement());
        break;
      case "client-cod":
        this.getListClientManagement(
          new RequestListClientManagement({
            isCod: true
          })
        );
        break;
      default:
        break;
    }
  }

  @Mutation
  setSearch(val: string) {
    this.search = val;
  }

  @Mutation
  setFirstPage() {
    this.lastBalanceList.pagination.page = 1;
  }
  @Mutation
  setFirstRequest(val: boolean) {
    this.firstRequest = val;
  }

  @Mutation
  setLoading(val: boolean) {
    this.isLoading = val;
  }
  @Mutation
  setError(val: boolean) {
    this.isError = val;
  }
  @Mutation
  setErrorCause(val: string) {
    this.errorCause = val;
  }
}

export const LastBalanceController = getModule(LastBalanceStore);
