
import { Vue, Options } from "vue-class-component";
import { formatPrice } from "@/app/infrastructures/misc/Utils";
import { LastBalanceController } from "@/app/ui/controllers/LastBalanceController";
import { LastBalanceData } from "@/domain/entities/LastBalance";
import PageListLastBalance from "@/app/ui/views/finance/last-balance/components/page-list-last-balance.vue";

@Options({
  components: {
    PageListLastBalance
  }
})
export default class ListLastBalanceClientCOD extends Vue {
  tab = "client-cod";
  mounted() {
    LastBalanceController.initLastBalanceList(this.tab);
  }
  // Table
  columns = [
    {
      name: "Kode Klien COD",
      styleHead: "w-3/12 text-left whitespace-no-wrap",
      styleBody: "text-red-lp-200 text-center",
      render: (item: LastBalanceData) => {
        return `<span class='text-left text-red-lp-200 flex justify-center'>${item.codeClientPartner}</span>`;
      }
    },
    {
      name: "Nama Klien COD",
      styleHead: "w-3/12 text-left whitespace-no-wrap",
      styleBody: "",
      render: (item: LastBalanceData) => {
        return `<span class="overflow-ellipsis text-black-lp-300">${item.nameClientPartner}</span>`;
      }
    },
    {
      name: "Saldo Terakhir",
      styleHead: "w-3/12 text-left whitespace-no-wrap",
      render: (item: LastBalanceData) => {
        return `${this.formatBalanceStyle(item.lastBalance)}`;
      }
    },
    {
      name: "Transaksi Terakhir",
      styleHead: "w-3/12 text-left whitespace-no-wrap",
      render: (item: any) => {
        return `<div class='flex text-black-lp-300'>${item.lastTransaction}</div>`;
      }
    }
  ];

  formatBalanceStyle(balance: number) {
    return `<span class="overflow-ellipsis ${
      balance < 0 ? "text-red-lp-750" : "text-green-lp-100"
    }"> ${formatPrice(balance, "+ ")}</span>`;
  }
}
