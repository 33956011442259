
import { Vue, prop } from "vue-class-component";
import { LastBalanceController } from "@/app/ui/controllers/LastBalanceController";

class Props {
  placeholderSearch = prop<string>({
    default: ""
  });
  tab = prop<string>({
    default: ""
  });
  columns = prop<Array<any>>({
    default: []
  });
}

export default class PageListLastBalance extends Vue.with(Props) {
  fetchLastBalanceList() {
    LastBalanceController.fetchLastBalanceList(this.tab);
  }
  get lastBalanceList() {
    return LastBalanceController.lastBalanceList;
  }
  get isLoading() {
    return LastBalanceController.isLoading;
  }
  get isError() {
    return LastBalanceController.isError;
  }
  get errorCause() {
    return LastBalanceController.errorCause;
  }
  get firstRequest() {
    return LastBalanceController.firstRequest;
  }

  // search
  get searchValue() {
    return LastBalanceController.search;
  }
  set searchValue(value: string) {
    LastBalanceController.setSearch(value);
  }
  onSearchLastBalance(value: string) {
    LastBalanceController.setFirstPage();
    this.searchValue = value;
    this.fetchLastBalanceList();
  }
  onClearSearchLastBalance() {
    LastBalanceController.setFirstPage();
    this.onSearchLastBalance("");
  }
}
