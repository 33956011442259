
import { Vue } from "vue-class-component";

export default class LastBalance extends Vue {
  // Tabs
  tabs = [
    { name: "balance-pos", title: "POS" },
    { name: "balance-client", title: "Klien" },
    { name: "balance-cod-client", title: "Klien COD" }
  ];

  changeTabs(value: string) {
    this.$router.push({
      name: value
    });
  }

  isTabActive(name: string) {
    return name === this.$route.name;
  }
}
