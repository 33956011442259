import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "bg-white flex flex-col text-12px xxl:text-14px overflow-hidden h-screen relative" }
const _hoisted_2 = { class: "flex w-9/12 gap-x-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Search = _resolveComponent("Search")!
  const _component_Header = _resolveComponent("Header")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_TableV2 = _resolveComponent("TableV2")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_Header, { class: "items-center" }, {
      default: _withCtx(() => [
        _createVNode("div", _hoisted_2, [
          _createVNode(_component_Search, {
            value: _ctx.searchValue,
            onInput: _ctx.onSearchLastBalance,
            class: "w-4/12",
            placeholder: _ctx.placeholderSearch,
            onClear: _ctx.onClearSearchLastBalance
          }, null, 8, ["value", "onInput", "placeholder", "onClear"])
        ])
      ]),
      _: 1
    }),
    (
        _ctx.firstRequest &&
          _ctx.lastBalanceList.data.length === 0 &&
          !_ctx.isLoading &&
          !_ctx.isError
      )
      ? (_openBlock(), _createBlock(_component_ErrorMessage, {
          key: 0,
          "error-title": "Tidak Ada Saldo Terakhir",
          "error-image": "noData_empty",
          "error-message": "POS atau Klien masih belum melakukan top up saldo"
        }))
      : (_ctx.lastBalanceList.data.length === 0 && !_ctx.isLoading && !_ctx.isError)
        ? (_openBlock(), _createBlock(_component_ErrorMessage, {
            key: 1,
            "error-title": "Tidak Ada Saldo Terakhir",
            "error-image": "data-not-found",
            "error-message": "POS atau Klien masih belum melakukan top up saldo"
          }))
        : (_openBlock(), _createBlock(_component_TableV2, {
            key: 2,
            onRequest: _ctx.fetchLastBalanceList,
            pagination: _ctx.lastBalanceList.pagination,
            "onUpdate:pagination": _cache[1] || (_cache[1] = ($event: any) => (_ctx.lastBalanceList.pagination = $event)),
            loading: _ctx.isLoading,
            columns: _ctx.columns,
            data: _ctx.lastBalanceList.data,
            class: "my-2",
            errorCause: _ctx.errorCause,
            paginationStyle: "v3",
            borderBottom: false
          }, null, 8, ["onRequest", "pagination", "loading", "columns", "data", "errorCause"]))
  ]))
}