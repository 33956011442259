import { QueryParamsEntities } from "@/domain/entities/MainApp";

export class RequestListLastBalance {
  actorIds = "";
  actorType = "";
  limit = 20;

  constructor(fields?: Partial<RequestListLastBalance>) {
    Object.assign(this, fields);
  }

  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}
