import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageListLastBalance = _resolveComponent("PageListLastBalance")!

  return (_openBlock(), _createBlock(_component_PageListLastBalance, {
    placeholderSearch: "Nama Klien",
    tab: _ctx.tab,
    columns: _ctx.columns
  }, null, 8, ["tab", "columns"]))
}